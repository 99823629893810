import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import StudioView from "@/views/StudioView.vue";
import SolutionView from "@/views/SolutionView.vue";
import ModelView from "@/views/ModelView.vue";
import AboutUsView from "@/views/AboutUsView.vue";
import NewsView from "@/views/NewsView.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name: "home",
    alias: "/",
    component: HomeView,
    meta: {
      hideTitle: true,
    },
  },
  {
    path: "/studio",
    name: "studio",
    component: StudioView,
    meta: {
      hideTitle: true,
    },
  },
  {
    path: "/solution",
    name: "solution",
    component: SolutionView,
    meta: {
      hideTitle: true,
    },
  },
  {
    path: "/details",
    name: "details",
    component: ModelView,
    meta: {
      hideTitle: true,
    },
  },
  {
    path: "/about-us",
    name: "about-us",
    component: AboutUsView,
    meta: {
      hideTitle: true,
    },
  },
  {
    path: "/about-us-privacy_policy",
    name: "about-us-privacy_policy",
    component: PrivacyPolicy,
  },
  {
    path: "/about-us-news",
    name: "about-us-news",
    component: NewsView,
    meta: {
      hideTitle: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
