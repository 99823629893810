<template>
  <div class="contact-us-wrap">
    <div v-if="!showCustomerState" class="customer-service" @click="showCustomerState = 1"></div>
    <div v-if="showCustomerState == 1 || showCustomerState == 3" class="customer-close" @click="showCustomerState = 0">
    </div>
    <div v-if="showCustomerState == 1 || showCustomerState == 3" class="customer-service-content">
      <div class="fontBold">购买咨询</div>
      <div class="fontBold">021-54107208</div>
      <div class="fontNormal" style="margin-bottom: 24rem;">工作日：9:30-18:30（北京时间）</div>
      <div class="fontBold">联系邮箱</div>
      <div class="fontBold">yang.he@njyjxr.com</div>
      <div class="fontNormal" style="margin-bottom: 24rem;">48小时之内回复</div>
      <div class="add-customer" @click="showCustomer">添加客服</div>
      <div class="fontNormal" style="text-align: center;">添加客服微信，提供一对一支持</div>
    </div>

    <div v-if="!changeState">
      <div class="contact-us" v-if="showCustomerState > 1">
        <div class="title">成功提交信息，可享专业客服一对一支持</div>
        <div class="form-wrap">
          <el-input class="form-input" :class="checkEmpty.companyName ? 'error-cn' : ''"
            v-model="contactInfo.companyName" placeholder="公司名称" />
          <el-input class="form-input" :class="checkEmpty.name ? 'error-n' : ''" v-model="contactInfo.name"
            placeholder="姓名" />
          <el-input class="form-input" :class="showError || checkEmpty.phone ? 'error-text' : ''"
            v-model="contactInfo.phone" placeholder="电话" />
          <el-select placeholder="所属行业" :class="checkEmpty.industry ? 'error-i' : ''" v-model="contactInfo.industry"
            class="select-default" popper-class="select-option">
            <el-option v-for="(item, index) in industryList" :key="index" :label="item.name" :value="item.value" />
          </el-select>
        </div>
        <div class="submit-btn" @click="submitEvent">提交信息</div>
      </div>
      <div class="contact-service" v-if="showCustomerState == 2">
        <div class="closed" @click="showCustomerState = 0"></div>
        <div class="title">联系我们的团队</div>
        <div class="title-tips">你可以通过以下几种方式联系我们，我们将为您提供专业的解答</div>
        <div class="service-list">
          <div style="padding: 29rem 0;">
            <img src="@/assets/images/home/customer.jpg" />
            <span>扫码添加客服微信</span>
          </div>
          <div>
            <div class="fontBold">购买咨询</div>
            <div class="fontBold">021-54107208</div>
            <div class="fontNormal">工作日：9:30-18:30（北京时间）</div>
          </div>
          <div>
            <div class="fontBold">联系邮箱</div>
            <div class="fontBold">yang.he@njyjxr.com</div>
            <div class="fontNormal">48小时之内回复</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, onMounted, watch } from 'vue'
import { addConsult } from '@/api'

const checkEmpty: any = ref({})
const showError = ref(false)

const props = defineProps({
  changeState: {
    default: null,
    type: Function
  },
  stateValue: {
    default: null,
    type: Number
  }
})

const showCustomerState = ref(0)
const contactInfo: any = reactive({
  companyName: '',
  name: '',
  phone: '',
  industry: null
})
const industryList = [
  {
    name: '文娱',
    value: 1,
  },
  {
    name: '工业',
    value: 2,
  },
  {
    name: '教育',
    value: 3,
  },
  {
    name: '其他',
    value: 4,
  }
]

const submitEvent = () => {
  let reg = /^(130|131|132|133|134|135|136|137|138|139|145|146|147|148|149|150|151|152|153|155|156|157|158|159|166|170|171|173|174|175|176|177|178|180|181|182|183|184|185|186|187|188|189|191|198|199)\d{8}$/
  Object.keys(contactInfo).forEach((k) => {
    checkEmpty.value[k] = !contactInfo[k]
  })
  if (!reg.test(contactInfo.phone.replace(/\s+/g, "")) || !contactInfo.phone) {
    showError.value = true;
    return
  } else if (!contactInfo.companyName || !contactInfo.name || !contactInfo.industry) {
    return
  }
  addConsult({ ...contactInfo }).then((res: any) => {
    showCustomerState.value = 2;
    contactInfo.companyName = ''
    contactInfo.name = ''
    contactInfo.phone = ''
    contactInfo.industry = null
    showError.value = false;
    checkEmpty.value = {}
  })
}

const showCustomer = () => {
  if (!props.changeState) {
    showCustomerState.value = 3
    return
  }
  showCustomerState.value = 1
  const bgVideo: any = document.querySelector('.bg-video');
  if (bgVideo) {
    window.scrollTo(0, bgVideo.offsetTop)
  }
}

watch(showCustomerState, (newState) => {
  props.changeState && props.changeState(newState)
})

watch(() => props.stateValue, (newState) => {
  if (newState != showCustomerState.value) {
    showCustomerState.value = newState;
  }
})
</script>
<style scoped lang="less">
.contact-us-wrap {
  position: fixed;
  right: 39rem;
  top: 600rem;
  width: 86rem;
  height: 86rem;
  z-index: 999;
}

.customer-service,
.customer-close {
  width: 86rem;
  height: 86rem;
  position: absolute;
  bottom: 0;
  right: 0;
  background: url(@/assets/images/home/customer-service.png);
  background-size: 100% 100%;
  cursor: pointer;
}

.customer-close {
  background: url(@/assets/images/home/customer-close.png);
  background-size: 100% 100%;
}

.customer-service-content {
  width: 268rem;
  height: 341rem;
  background: #FFFFFF;
  box-shadow: 0px 2px 20rem 0px rgba(0, 0, 0, 0.2);
  border-radius: 12rem;
  position: absolute;
  right: 0;
  bottom: 98rem;
  text-align: left;
  padding: 24rem;
  box-sizing: border-box;

  .fontBold {
    font-weight: bold;
    font-size: 18rem;
    color: #000000;
    margin-bottom: 4rem;
  }

  .fontNormal {
    font-weight: 500;
    font-size: 14rem;
    color: #9C9C9C;
  }

  .add-customer {
    width: 220rem;
    height: 46rem;
    line-height: 46rem;
    background: #0675FF;
    border-radius: 23rem;
    border: 1px solid #979797;
    text-align: center;
    font-weight: bold;
    font-size: 18rem;
    color: #FFFFFF;
    margin-bottom: 12rem;
    cursor: pointer;
  }
}

.contact-us {
  width: 1266rem;
  height: 396rem;
  background: url('@/assets/images/contact-us-bg.png');
  background-size: 100% 100%;
  position: absolute;
  left: -1470rem;
  top: -288rem;
  border-radius: 12rem;
  padding: 62rem 72rem;
  box-sizing: border-box;

  .title {
    font-weight: 500;
    font-size: 32rem;
    color: #D3D3D3;
  }

  .form-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 59rem;

    &>div {
      width: 267rem;
      height: 40rem;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 8rem;
    }
  }

  .submit-btn {
    width: 152rem;
    height: 56rem;
    line-height: 56rem;
    background: #0675FF;
    border-radius: 8rem;
    text-align: center;
    font-weight: 500;
    font-size: 24rem;
    color: #FFFFFF;
    margin-top: 80rem;
    margin-left: 470rem;
    cursor: pointer;
  }
}

.contact-service {
  position: relative;
  width: 1266rem;
  height: 396rem;
  position: absolute;
  left: -1470rem;
  top: -288rem;
  border-radius: 12rem;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 12rem;
  border: 1px solid #464646;
  backdrop-filter: blur(12rem);
  padding: 40rem 87rem;
  box-sizing: border-box;

  .title {
    font-weight: 500;
    font-size: 32rem;
    color: #fff;
  }

  .title-tips {
    font-weight: 400;
    font-size: 14rem;
    color: #FFFFFF;
    margin-top: 12rem;
    margin-bottom: 50rem;
  }

  .service-list {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 14rem;
      color: #FFFFFF;

      img {
        width: 123rem;
        height: 123rem;
        margin-bottom: 6rem;
      }
    }

    &>div {
      width: 356rem;
      height: 187rem;
      background: #151515;
      border-radius: 12rem;
      border: 1px solid #4A4A4A;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 40rem 0;
      box-sizing: border-box;

      .fontBold {
        font-weight: bold;
        font-size: 20rem;
        color: #FFFFFF;
      }

      .fontNormal {
        font-weight: 500;
        font-size: 14rem;
        color: #7C7C7C;
      }

    }
  }

  .closed {
    width: 30rem;
    height: 30rem;
    background: url(~@/assets/images/home/close.png);
    background-size: 100% 100%;
    position: absolute;
    right: 30rem;
    top: 25rem;
    cursor: pointer;
  }
}
</style>