<template>
  <header-view :change-path="changePath" :hideTitle="hideTitle"></header-view>
  <div class="router-wrap">
    <router-view />
    <bottom-navigation></bottom-navigation>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import HeaderView from '@/components/HeaderView.vue';
import BottomNavigation from '@/components/BottomNavigation.vue';

const router = useRouter()
const store = useStore()
const hideTitle = ref(false)


const changePath = (path: string) => {
  console.log(path)
}

// 路由拦截，这里主要根据路由确定一些组件的显隐性
router.beforeEach((to, from, next) => {
  hideTitle.value = Boolean((to.meta && to.meta.hideTitle));
  const pathInfo = JSON.stringify({ query: to.query || {} })
  window.sessionStorage.setItem('path', pathInfo)
  window.localStorage.setItem('activePath', to.fullPath)
  store.state.activePath = to.fullPath;
  next();
});

onMounted(() => {
  setRem()
  window.addEventListener('resize', () => {
    setRem()
  }, false)
  // 判断是不是移动端
})

const setRem = () => {
  let rootDom: any = document.querySelector('html');
  let rootWidth = document.documentElement.clientWidth || document.body.clientWidth;

  var userAgent = navigator.userAgent.toLowerCase();
  if (
    userAgent.match(
      /(phone|pad|pod|iphone|ipod|ios|ipad|android|mobile|blackberry|iemobile|mqqbrowser|juc|fennec|wosbrowser|browserng|webos|symbian|windows phone|windows mobile|windows ce|ucweb|rv:1.2.3.4|midp)/i
    )
  ) {
    rootDom.style.fontSize = rootWidth / 1920 + 'px';
    store.state.userAgent = 'mobile'
  } else {
    rootDom.style.fontSize = Math.min(Math.max(rootWidth / 1920, 0.8), 1.5) + 'px';
    store.state.userAgent = 'pc'
  }

}
</script>
<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 1);
  color: #333333;
}

body {
  font-size: 14rem;
  font-family: PingFangSC-Regular;
}

#app {
  font-family: "PingFang SC","Lantinghei SC","Microsoft YaHei","HanHei SC","Helvetica Neue","Open Sans","Hiragino Sans GB",'微软雅黑',STHeiti,"WenQuanYi Micro Hei",Arial,SimSun,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.router-wrap {
  position: relative;
  padding-bottom: 400rem;
  font-family: PingFangSC-Regular;
  box-sizing: border-box;
  min-height: calc(100vh - 60rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #000000;

  &>div {
    width: 100%;
  }
}

.error-text, .error-cn, .error-n, .error-i {
  position: relative;
}
.error-text::after, .error-cn::after, .error-n::after, .error-i::after {
  content: '输入正确的手机号';
  position: absolute;
  left: 0;
  bottom: -26rem;
  width: 180rem;
  height: 20rem;
  line-height: 20rem;
  font-size: 13rem;
  color: #d81417;
  text-align: left;
}

.error-cn::after {
  content: '请输入公司名称'
}

.error-n::after {
  content: '请输入姓名'
}

.error-i::after {
  content: '请选择所属行业'
}

.title-font-style {
  font-weight: 600;
  font-size: 60rem;
  color: #333333;
  font-family: PingFangSC-Medium;
}

.form-wrap {
  .el-input__wrapper {
    background-color: transparent;
    box-shadow: none !important;
    border: none !important;
  }

  .el-select__wrapper {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    height: 100%;
  }

  .el-input__inner,
  .el-select__placeholder {
    font-weight: 500;
    font-size: 16px;
    color: #CDCDCD;
  }
}
.news-detail img {
  max-width: 100%;
  height: auto;
}

.news-detail video {
  max-width: 100%;
  height: auto;
}

.news-detail .detail-html {
  padding: 30rem;
  box-sizing: border-box;
  color: rgb(71, 69, 69);
}
</style>
