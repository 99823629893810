<template>
  <div class="about-us">
    <div class="about-us-banner">
      <img :src="loadUrl.banner_bg" />
      <div>关于我们</div>
    </div>
    <div class="about-us-content">
      <div class="introduce">
        <div>公司介绍</div>
        <div>
          <div>混空是一家专注元宇宙数实融合场景的公司，深耕XR+AI技术突破，以技术平台为发展引擎，为各行业提供集底层算法技术研发、SaaS化产品交付、软件定制开发等服务于一体的元宇宙解决方案。
          </div>
          <div>以行业应用为导向，公司在文娱、工业、教育等领域已有诸多成功案例，在元宇宙应用、数智化升级方向展开工作，切实帮助客户提质增效。同时，面向大文娱垂直领域，客户可通过公司自研的元宇宙数实融合应用平台——混空
            Studio，落地人人可创作可参与的元宇宙展示及营销场景，应用前景广阔。</div>
          <div>公司获得国家双软企业认定和国家科技型中小企业认定，已申请近40项软件著作和知识产权，并凭借数实融合技术多次斩获国内投资价值类奖项，成为元宇宙、数智化领域的头部公司。</div>
        </div>
      </div>
      <div class="company-profile">
        <img :src="loadUrl.company_profile"/>
      </div>
      <!-- <div class="honor">
        <div class="title">荣誉及资质</div>
      </div> -->
      <div class="ryzz honor">
        <div class="title">荣誉资质</div>
        <div class="prev" @click="prevEvent"></div>
        <div class="next" @click="nextEvent"></div>
        <div class="ryzz-list">
          <div class="honors-box" :style="{ left: -(activeIndex - 1) * 353 + 'rem' }">
            <div v-for="(item, index) in showHonors" :key="index" :class="index == activeIndex ? 'active' : ''">
              <img :src="item.url" />
              <div>
                <div v-for="(e, i) in item.illustrate" :key="i">{{ e }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="xwzx">
        <div class="title-font-style title">公司动态</div>
        <div class="xwzx-box">
          <div @click="toOtherPath(newsList[activeNewsIndex].relatedHtmlId, newsList[activeNewsIndex].title)">
            <img v-if="newsList[activeNewsIndex]" :src="newsList[activeNewsIndex].ossPath || ''" />
          </div>
          <div>
            <div class="news-content">
              <div v-for="(e, i) in newsList" :key="i" @click="toOtherPath(e.relatedHtmlId, e.title)"
                :class="activeNewsIndex == i ? 'active' : ''">
                <div>{{ e.title }}</div>
                <div>{{ e.times }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-video">
      <video :src="loadUrl.particle_moment_mp4" autoplay loop muted></video>
      <div class="contact-us">
        <div class="title">成功提交信息，可享专业客服一对一支持</div>
        <div class="form-wrap">
          <el-input class="form-input" :class="checkEmpty.companyName ? 'error-cn' : ''"
            v-model="contactInfo.companyName" placeholder="公司名称" />
          <el-input class="form-input" :class="checkEmpty.name ? 'error-n' : ''" v-model="contactInfo.name"
            placeholder="姓名" />
          <el-input class="form-input" :class="(showError || checkEmpty.phone) ? 'error-text' : ''"
            v-model="contactInfo.phone" placeholder="电话" />
          <el-select placeholder="所属行业" :class="checkEmpty.industry ? 'error-i' : ''" v-model="contactInfo.industry"
            class="select-default" popper-class="select-option">
            <el-option v-for="(item, index) in industryList" :key="index" :label="item.name" :value="item.value" />
          </el-select>
        </div>
        <div class="submit-btn" @click="submitEvent">提交信息</div>
      </div>
      <div class="contact-service" v-if="showCustomerState == 2">
        <div class="closed" @click="showCustomerState = 0"></div>
        <div class="title">联系我们的团队</div>
        <div class="title-tips">你可以通过以下几种方式联系我们，我们将为您提供专业的解答</div>
        <div class="service-list">
          <div style="padding: 29rem 0;">
            <img src="@/assets/images/home/customer.jpg" />
            <span>扫码添加客服微信</span>
          </div>
          <div>
            <div class="fontBold">购买咨询</div>
            <div class="fontBold">021-54107208</div>
            <div class="fontNormal">工作日：9:30-18:30（北京时间）</div>
          </div>
          <div>
            <div class="fontBold">联系邮箱</div>
            <div class="fontBold">yang.he@njyjxr.com</div>
            <div class="fontNormal">48小时之内回复</div>
          </div>
        </div>
      </div>
      <contact-us :changeState="changeState" :state-value="showCustomerState"></contact-us>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted, onUnmounted, reactive } from 'vue'
import { selectCommonElement, addConsult } from '@/api'
import router from '@/router';
import store from '@/store';
import ContactUs from '@/components/ContactUs.vue';
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const showIndexs: any = ref([3, 0, 1, 2, 3])
let timer: any = null;
let timer2: any = null;
let timer3: any = null;
let clicks: any = false;
const newsList: any = ref([])
const honors = [
  {
    url: require('@/assets/images/about_us/ryzz1.png'),
    illustrate: ['2023上海最具投资潜力', '50佳创业企业']
  },
  {
    url: require('@/assets/images/about_us/ryzz2.png'),
    illustrate: ['“创客中国”元宇宙（人工智能）', '专业赛 一等奖']
  },
  {
    url: require('@/assets/images/about_us/ryzz3.png'),
    illustrate: ['2023数智创新优胜奖']
  },
  {
    url: require('@/assets/images/about_us/ryzz7.png'),
    illustrate: ['上海市创新创业大赛 优胜奖']
  },
]

const active_r_i = ref(1)
const showHonors: any = ref([])
const activeIndex = ref(2)
const activeNewsIndex = ref(0)
const contactInfo: any = reactive({
  companyName: '',
  name: '',
  phone: '',
  industry: null
})
const showCustomerState = ref(0)
const industryList = [
  {
    name: '文娱',
    value: 1,
  },
  {
    name: '工业',
    value: 2,
  },
  {
    name: '教育',
    value: 3,
  },
  {
    name: '其他',
    value: 4,
  }
]
const showError = ref(false)
const checkEmpty: any = ref({})

const submitEvent = () => {
  let reg = /^(130|131|132|133|134|135|136|137|138|139|145|146|147|148|149|150|151|152|153|155|156|157|158|159|166|170|171|173|174|175|176|177|178|180|181|182|183|184|185|186|187|188|189|191|198|199)\d{8}$/
  Object.keys(contactInfo).forEach((k) => {
    checkEmpty.value[k] = !contactInfo[k]
  })
  if (!reg.test(contactInfo.phone.replace(/\s+/g, "")) || !contactInfo.phone) {
    showError.value = true;
    return
  } else if (!contactInfo.companyName || !contactInfo.name || !contactInfo.industry) {
    return
  }
  addConsult({ ...contactInfo }).then((res: any) => {
    showCustomerState.value = 2;
    contactInfo.companyName = ''
    contactInfo.name = ''
    contactInfo.phone = ''
    contactInfo.industry = null
    showError.value = false;
    checkEmpty.value = {}
  })
}

const changeState = (val: number) => {
  showCustomerState.value = val;
}


const toOtherPath = (relatedHtmlId: number, title: string) => {
  router.push(`/details?relatedHtmlId=${relatedHtmlId}`)
  store.state.pageTitleStr = `新闻中心 / 新闻详情 / ${title}`
}

const prevEvent = () => {
  if (clicks) return;
  clicks = true;
  (document.querySelector('.honors-box') as any).style.transition = 'all .5s';
  (document.querySelectorAll('.honors-box>div') as any).forEach((ele: any) => {
    ele.style.transition = 'all .5s'
  });
  if (active_r_i.value != 0) {
    active_r_i.value -= 1;
  } else {
    active_r_i.value = honors.length - 1
  }
  activeIndex.value -= 1;
}

const nextEvent = () => {
  if (clicks) return;
  clicks = true;
  (document.querySelector('.honors-box') as any).style.transition = 'all .5s';
  (document.querySelectorAll('.honors-box>div') as any).forEach((ele: any) => {
    ele.style.transition = 'all .5s'
  });
  if (active_r_i.value != honors.length - 1) {
    active_r_i.value += 1;
  } else {
    active_r_i.value = 0
  }
  activeIndex.value += 1;
}
onMounted(async () => {
  showHonors.value = showIndexs.value.map((k: number) => honors[k]);
  clearInterval(timer)
  timer = setInterval(() => {
    nextEvent()
  }, 3000)

  selectCommonElement({ columnId: 5, appId: 2 }).then((res: any) => {
    newsList.value = res.slice(0, 4).map((e: any) => {
      const date = new Date(e.newsTimestamp || e.createTime)
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();
      e.times = `${y}-${m}-${d}`
      return e;
    })
  })

  clearInterval(timer3)
  timer3 = setInterval(() => {
    activeNewsIndex.value += 1;
    if (activeNewsIndex.value > 3) {
      activeNewsIndex.value = 0;
    }
  }, 3000)

  const particle_moment_mp4: any = await asynchronousRetrieval('webimg/images/home/particles-moment.mp4');
  const banner_bg: any = await asynchronousRetrieval('webimg/images/about_us/banner-bg.png');
  const company_profile: any = await asynchronousRetrieval('webimg/images/about_us/company-profile.png');
  loadUrl.value = { particle_moment_mp4, banner_bg, company_profile }
})

onUnmounted(() => {
  clearInterval(timer)
  clearInterval(timer2)
  clearInterval(timer3)
})

watch(active_r_i, (newState) => {
  clearInterval(timer)
  showIndexs.value = showIndexs.value.map((v: number, i: number) => {
    if (i < 2) {
      if ((newState - (2 - i)) >= 0) {
        return newState - (2 - i)
      } else {
        return (honors.length + newState) - (2 - i)
      }
    }
    if (i == 2) return newState
    if (i > 2) {
      if ((newState < honors.length - 2) || (newState + (i - 2) < honors.length)) {
        return newState + (i - 2)
      } else {
        return newState + (i - 2) - honors.length
      }
    }
  });
  clearTimeout(timer2)
  timer2 = setTimeout(() => {
    (document.querySelector('.honors-box') as any).style.transition = 'none';
    (document.querySelectorAll('.honors-box>div') as any).forEach((ele: any) => {
      ele.style.transition = 'none'
    });
    showHonors.value = showIndexs.value.map((k: number) => honors[k]);
    activeIndex.value = 2
    clicks = false
    clearInterval(timer)
    timer = setInterval(() => {
      nextEvent()
    }, 3000)
  }, 500)
})
</script>
<style scoped lang="less">
.about-us-banner {
  position: relative;
  height: 420rem;
  line-height: 505rem;
  text-align: center;
  font-weight: 600;
  font-size: 64rem;
  color: #FFFFFF;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  &>div {
    position: relative;
    z-index: 1;
  }
}

.about-us-content {
  height: 2131rem;
  background: linear-gradient(180deg, #FFFFFF 0%, #F6FAFF 79%, #A3C7F4 93%);
  padding-top: 96rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .introduce {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 1120rem;

    &>div:first-child {
      font-weight: normal;
      font-size: 41rem;
      color: #000000;
    }

    &>div:last-child {
      width: 742rem;
      font-weight: 400;
      font-size: 16rem;
      color: #000000;
      text-align: left;

      &>div {
        margin-bottom: 42rem;
      }
    }
  }

  .company-profile {
    margin-top: 20rem;
    width: 1120rem;
    height: 403rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    font-size: 41rem;
    color: #000000;
    text-align: left;
  }

  .honor {
    margin-top: 142rem;
    width: 1098rem;
  }
}

.ryzz {
  position: relative;
  width: 1098rem;
  display: inline-block;

  .ryzz-list {
    position: relative;
    left: 72rem;
    width: 100%;
    height: 400rem;
    margin-top: 55rem;
    margin-bottom: 200rem;
    overflow: hidden;


    .honors-box {
      position: absolute;
      top: 0;
      width: 1804rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &>div {
        position: relative;
        width: 343rem;
        height: 350rem;
        border-radius: 8rem;
        padding-top: 10rem;
        box-sizing: border-box;
        text-align: center;
        border: 1px solid #D8D8D8;

        img {
          width: 217rem;
          height: 224rem;
        }

        &>div {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 87rem;
          padding: 15rem 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          box-sizing: border-box;
          background-color: #2E76FF;
          font-weight: bold;
          font-size: 16rem;
          color: #FFFFFF;
          line-height: 28rem;
          border-radius: 0 0 8rem 8rem;
        }
      }

      .active {
        transform: scale(1.14);
        margin: 24.5rem;
        box-shadow: 0px 10rem 12rem 0px rgba(46, 118, 255, 0.2), 0px 0px 4rem 0px rgba(46, 118, 255, 0.2);
      }
    }
  }

  .prev,
  .next {
    position: absolute;
    top: 292rem;
    width: 50rem;
    height: 50rem;
    padding: 0;
    cursor: pointer;

    &:hover {
      background: url(~@/assets/images/home/rightA.png);
      background-size: 100% 100%;
      transform: rotateY(180deg);
    }
  }

  .prev {
    background: url(~@/assets/images/home/left.png);
    background-size: 100% 100%;
    left: 0;
  }

  .next {
    background: url(~@/assets/images/home/left.png);
    background-size: 100% 100%;
    right: -144rem;
    transform: rotate(180deg);

    &:hover {
      background: url(~@/assets/images/home/rightA.png);
      background-size: 100% 100%;
      transform: rotateY(0deg);
    }
  }
}

.xwzx {
  width: 1120rem;
  // background-color: #fff;
  // padding-top: 100rem;
  // padding-bottom: 200rem;

  .more-box {
    font-weight: 400;
    font-size: 18rem;
    color: #D81417;
    font-family: PingFangSC-Medium;
    margin-top: 20rem;

    &>div {
      display: inline-block;
      cursor: pointer;
    }

    span {
      vertical-align: middle;
    }

    img {
      width: 6rem;
      height: 11rem;
      vertical-align: middle;
      margin-left: 6rem;
    }

    padding-bottom: 73rem;
  }

  .xwzx-box {
    width: 1120rem;
    // height: 400rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 36rem;
    background: #FFFFFF;
    border-radius: 12rem;
    padding: 24rem;
    box-sizing: border-box;
    font-size: 0;

    &>div:first-child {
      position: relative;
      width: 640rem;
      height: 360rem;
      background-color: #efefef;
      border-radius: 8rem;
      overflow: hidden;
      cursor: pointer;

      &>div {
        width: 100%;
        height: 75rem;
        line-height: 75rem;
        position: absolute;
        left: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(66, 64, 64, 0) 0%, #1F1E1E 100%);
        border-radius: 0 0 8rem 8rem;
        padding-left: 16rem;
        box-sizing: border-box;
        font-weight: 600;
        font-size: 22rem;
        color: #FFFFFF;
        text-align: left;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    &>div:last-child {
      width: 387rem;
      height: 360rem;
      padding-top: 7rem;
      box-sizing: border-box;

      .news-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        &>div {
          // height: 68rem;
          // margin-top: 26rem;
          text-align: left;
          font-family: PingFangSC-Medium;
          font-weight: bold;
          font-size: 18rem;
          color: #414141;
          cursor: pointer;

          &>div:first-child {
            color: #000000;
          }

          &>div:last-child {
            font-weight: 400;
            font-size: 14rem;
            color: #414141;
            margin-top: 4rem;
          }
        }

        &>div:first-child {
          margin-top: 0;
        }

        .active {
          position: relative;

          &>div {
            color: #2E76FF !important;
          }

          &::before {
            content: '';
            width: 8rem;
            height: 8rem;
            background-color: #2E76FF;
            border-radius: 50%;
            position: absolute;
            left: -20rem;
            top: 13rem;
          }
        }
      }
    }
  }
}

.bg-video {
  position: relative;
  // width: 1920rem;
  height: 620rem;

  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .contact-us {
    width: 1266rem;
    height: 396rem;
    background: rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-clip: padding-box;
    backdrop-filter: blur(6rem);
    position: absolute;
    left: 50%;
    top: 120rem;
    margin-left: -633rem;
    border-radius: 12rem;
    padding: 62rem 72rem;
    box-sizing: border-box;

    .title {
      font-weight: 500;
      font-size: 32rem;
      color: #D3D3D3;
    }

    .form-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 59rem;

      &>div {
        position: relative;
        width: 267rem;
        height: 40rem;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 8rem;
      }
    }

    .submit-btn {
      width: 152rem;
      height: 56rem;
      line-height: 56rem;
      background: #0675FF;
      border-radius: 8rem;
      text-align: center;
      font-weight: 500;
      font-size: 24rem;
      color: #FFFFFF;
      margin-top: 80rem;
      margin-left: 470rem;
      cursor: pointer;
    }
  }

  .contact-service {
    position: relative;
    width: 1266rem;
    height: 396rem;
    position: absolute;
    left: 50%;
    top: 120rem;
    margin-left: -633rem;
    border-radius: 12rem;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 12rem;
    border: 1px solid #464646;
    backdrop-filter: blur(12rem);
    padding: 40rem 87rem;
    box-sizing: border-box;

    .title {
      font-weight: 500;
      font-size: 32rem;
      color: #fff;
    }

    .title-tips {
      font-weight: 400;
      font-size: 14rem;
      color: #FFFFFF;
      margin-top: 12rem;
      margin-bottom: 50rem;
    }

    .service-list {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &>div:first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 14rem;
        color: #FFFFFF;

        img {
          width: 123rem;
          height: 123rem;
          margin-bottom: 6rem;
        }
      }

      &>div {
        width: 356rem;
        height: 187rem;
        background: #151515;
        border-radius: 12rem;
        border: 1px solid #4A4A4A;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 40rem 0;
        box-sizing: border-box;

        .fontBold {
          font-weight: bold;
          font-size: 20rem;
          color: #FFFFFF;
        }

        .fontNormal {
          font-weight: 500;
          font-size: 14rem;
          color: #7C7C7C;
        }

      }
    }

    .closed {
      width: 30rem;
      height: 30rem;
      background: url(~@/assets/images/home/close.png);
      background-size: 100% 100%;
      position: absolute;
      right: 30rem;
      top: 25rem;
      cursor: pointer;
    }
  }
}
</style>