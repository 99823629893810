<template>
  <div class="entertainment">
    <div class="title-font-style">文娱解决方案</div>
    <div class="illustrate">
      <div>将元宇宙与内容资源融合创新，应用虚拟现实和新媒体技术打造沉浸式的时空新体验，实现“文化+科技”“文娱+消费”的新业态模式，推动文娱产业升级。</div>
    </div>
    <div class="subtitle">发展前景</div>
    <div class="fzqj-box">
      <div class="fzqj">
        <div v-for="(item, index) in prospects" :key="index">
          <img :src="item.url" />
          <div>
            <div class="title">
              <img :src="item.src" />
              <span>{{ item.title }}</span>
            </div>
            <div>{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="subtitle">方案优势</div>
    <div class="fays">
      <img :src="loadUrl.fays_bg" />
      <div>
        <img class="l1" :src="loadUrl.positioning" />
        <div class="l2">空间定位</div>
        <img class="l3" :src="loadUrl.triangle" />
        <div class="l4">准</div>
        <div class="l5">
          <div>自研空间定位算法</div>
          <div>厘米级视觉定位</div>
          <div>空间导航精准</div>
          <div>支持大空间定位</div>
        </div>
      </div>
      <div>
        <img class="l1" :src="loadUrl.arrange" />
        <div class="l2">交付部署</div>
        <img class="l3" :src="loadUrl.triangle" />
        <div class="l4">快</div>
        <div class="l5">
          <div>SaaS化服务</div>
          <div>快速调整内容布局</div>
          <div>2天内完成空间构建</div>
          <div>现场体验式交付</div>
        </div>
      </div>
      <div>
        <img class="l1" :src="loadUrl.edit" />
        <div class="l2">场景编辑</div>
        <img class="l3" :src="loadUrl.triangle" />
        <div class="l4">简</div>
        <div class="l5">
          <div>3D场景地图</div>
          <div>可视化交互</div>
          <div>“拖拉拽”操作</div>
          <div>所“建”即所得</div>
        </div>
      </div>
      <div>
        <img class="l1" :src="loadUrl.application" />
        <div class="l2">支持应用</div>
        <img class="l3" :src="loadUrl.triangle" />
        <div class="l4">多</div>
        <div class="l5">
          <div>AR/MR眼镜</div>
          <div>移动端APP</div>
          <div>小程序AR</div>
          <div>Web</div>
        </div>
      </div>
      <div>
        <img class="l1" :src="loadUrl.positioning" />
        <div class="l2">内容扩展性</div>
        <img class="l3" :src="loadUrl.triangle" />
        <div class="l4">强</div>
        <div class="l5">
          <div>数字多媒体</div>
          <div>动画特效</div>
          <div>手势互动</div>
          <div>3D数字人</div>
        </div>
      </div>
    </div>
    <div class="yycj">
      <div class="subtitle">应用场景</div>
      <div class="yycj-content">
        <video :src="loadUrl.entertainmentMp4" autoplay loop muted></video>
      </div>
    </div>
    <div class="yyal">
      <div class="subtitle">应用案例</div>
      <div class="yyal-content">
        <div @click="toDetail(item.relatedHtmlId, item.title)" v-for="(item, index) in casesList" :key="index">
          <img :src="item.ossPath" />
          <div class="content">
            <div class="title">{{ item.title }}</div>
            <div>{{ item.subTitle }}</div>
            <div class="detail">
              {{ item.introduction }}
            </div>
          </div>
        </div>
      </div>
      <div class="pagination">
        <div class="prev" @click="changeData(Math.max(0, paginationIndex - 1) + 1)"></div>
        <div @click="changeData(item)" v-for="(item, index) in paginationValue"
          :class="paginationIndex == index ? 'active' : ''" :key="item">{{
            item }}</div>
        <div class="next" @click="changeData(Math.min(paginationIndex + 1, paginationValue - 1) + 1)"></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const store = useStore()
const router = useRouter()
const casesList: any = ref({})
const casesListAll: any = ref([])
const prospects: any = ref(
  [
    {
      title: '消费升级',
      content: '当下，国内新型消费潜力迸发，以沉浸式旅游、文化旅游等为特点的文娱旅游正成为各地积极培育的消费增长点。同时，数实融合不断深化，实体经济数字化、智能化、绿色化转型不断加快，为经济社会发展持续赋能。',
    },
    {
      title: '政策引领',
      content: '工信部等五部门联合印发《元宇宙产业创新发展三年行动计划（2023-2025年）》，北上广深、南京等数十多个城市纷纷出台元宇宙发展相关政策，希望借势元宇宙的机会加速传统产业数字化转型并大力发展数字经济，同时尝试结合地方特色打造丰富的元宇宙标杆场景和应用。',
    },
    {
      title: '数智未来',
      content: '人类社会数字化、智能化是不可逆的发展趋势，AIGC的应用将会极大的提高人类的生产效率，也将会加快数实融合、数实互促、元宇宙的模态到来。',
    }
  ]
)
const paginationIndex = ref(0)
const paginationValue = ref(1)

const toDetail = (relatedHtmlId: number, title: string) => {
  router.push(`/details?relatedHtmlId=${relatedHtmlId}`)
  store.state.pageTitleStr = `解决方案 / 文娱解决方案 / ${title}`
}

const changeData = (value: number) => {
  paginationIndex.value = value - 1;
  casesList.value = [...casesListAll.value].slice((value - 1) * 5, value * 5)
}

// onMounted(() => {
//   selectCommonElement({ columnId: 4, appId: 2 }).then((res: any) => {
//     casesListAll.value = [...res].filter(e => e.industry == 1)
//     casesList.value = [...casesListAll.value].slice(0, 5)
//     paginationValue.value = Math.ceil(casesListAll.value?.length / 5) || 0
//   })
// })

onMounted(async () => {
  selectCommonElement({ columnId: 4, appId: 2, createTimeOrder: 0 }).then((res: any) => {
    casesListAll.value = [...res].filter(e => e.industry == 1)
    casesList.value = [...casesListAll.value].slice(0, 5)
    paginationValue.value = Math.ceil(casesListAll.value?.length / 5) || 0
  })

  const positioning: any = await asynchronousRetrieval('nfznxr-images/jjfa/positioning.png');
  const triangle: any = await asynchronousRetrieval('nfznxr-images/jjfa/triangle.png');
  const arrange: any = await asynchronousRetrieval('nfznxr-images/jjfa/arrange.png');
  const edit: any = await asynchronousRetrieval('nfznxr-images/jjfa/edit.png');
  const application: any = await asynchronousRetrieval('nfznxr-images/jjfa/application.png');
  const fzqj1: any = await asynchronousRetrieval('nfznxr-images/jjfa/fzqj1.png');
  const xiaofei: any = await asynchronousRetrieval('nfznxr-images/jjfa/xiaofei.png');
  const fzqj2: any = await asynchronousRetrieval('nfznxr-images/jjfa/fzqj2.png');
  const zhengce: any = await asynchronousRetrieval('nfznxr-images/jjfa/zhengce.png');
  const fzqj3: any = await asynchronousRetrieval('nfznxr-images/jjfa/fzqj3.png');
  const shuzhi: any = await asynchronousRetrieval('nfznxr-images/jjfa/shuzhi.png');
  const fays_bg: any = await asynchronousRetrieval('nfznxr-images/jjfa/fays-bg.png');
  const entertainmentMp4: any = await asynchronousRetrieval('webimg/video/entertainment.mp4');
  loadUrl.value = { positioning, triangle, arrange, edit, application, fzqj1, xiaofei, fzqj2, zhengce, fzqj3, shuzhi, fays_bg, entertainmentMp4 }

  prospects.value[0].url = fzqj1
  prospects.value[0].src = xiaofei
  prospects.value[1].url = fzqj2
  prospects.value[1].src = zhengce
  prospects.value[2].url = fzqj3
  prospects.value[2].src = shuzhi
})


</script>
<style scoped lang="less">
.entertainment {
  background-color: #EFEFEF;
  padding-top: 100rem;
  overflow: hidden;

  .illustrate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40rem 0 80rem;

    &>div {
      font-weight: 400;
      font-size: 18rem;
      color: #333333;
      width: 661rem;
      line-height: 30rem;
    }
  }

  .subtitle {
    position: relative;
    font-weight: 600;
    font-size: 28rem;
    color: #333333;
    line-height: 40rem;
    display: inline-block;
    padding: 0 10rem;

    &::after {
      content: '';
      width: 100%;
      height: 13rem;
      background: rgba(35, 117, 255, 0.2);
      position: absolute;
      left: 0;
      bottom: 3rem;
    }
  }

  .fzqj-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 60rem 0 80rem;

    .fzqj {
      width: 1206rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &>div {
        width: 394rem;
        font-size: 0;
        box-shadow: 0 0 7rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 8rem;
        overflow: hidden;

        &>img {
          width: 394rem;
          height: 330rem;
        }

        &>div {
          padding: 30rem 29rem;
          font-weight: 400;
          font-size: 16rem;
          color: #5C5C5C;
          line-height: 30rem;
          text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
          text-align: left;
          height: 290rem;
          box-sizing: border-box;
          background: #FFFFFF;

          .title {
            font-weight: 600;
            font-size: 22rem;
            color: #333333;
            line-height: 30px;
            text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
            font-family: PingFangSC-Medium;
            margin-bottom: 15rem;

            img {
              width: 47rem;
              height: 46rem;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }

  .fays {
    position: relative;
    width: 100vw;
    height: 530rem;
    margin-top: 80rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100rem;

    &>img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
    }

    &>div {
      position: relative;
      margin: 0 5rem;
      width: 232rem;
      height: 410rem;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 8rem;
      padding: 20rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      z-index: 1;

      .l1 {
        width: 80rem;
        height: 93rem;
      }

      .l2 {
        font-weight: 600;
        font-size: 18rem;
        color: #333333;
        line-height: 25rem;
        font-family: PingFangSC-Medium;
        margin-top: -10rem;
      }

      .l3 {
        width: 45rem;
        height: 31rem;
      }

      .l4 {
        font-weight: 600;
        font-size: 22rem;
        color: #4B7CFD;
        line-height: 30rem;
        font-family: PingFangSC-Medium;
        margin-top: 5rem;
      }

      .l5 {
        padding: 6rem 10rem;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 16rem;
        color: #333333;
        line-height: 40rem;
        width: 192rem;
        height: 172rem;
        background: rgba(75, 124, 253, 0.1);
        border-radius: 8rem;
        backdrop-filter: blur(10rem);
        margin-top: 10rem;
      }
    }
  }

  .yycj {
    background-color: #fff;
    padding-top: 60rem;

    .yycj-content {
      width: 100%;
      height: 629rem;
      margin-top: 50rem;
      display: flex;
      justify-content: center;
      align-items: center;

      video {
        width: 1120rem;
        height: 100%;
        object-fit: fill;
        border-radius: 24rem;
        border: 1px solid #979797;
      }
    }
  }

  .yyal {
    padding-top: 80rem;
    background-color: #fff;
    overflow: hidden;

    .yyal-content {
      margin: 80rem 0 40rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &>div {
        width: 1040rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 60rem;
        padding: 20rem;
        border-radius: 8rem;
        cursor: pointer;

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(46, 118, 255, 0.2), 0px 0px 4px 0px rgba(46, 118, 255, 0.2);
        }

        img {
          width: 400rem;
          height: 224rem;
        }

        .content {
          width: 607rem;
          height: 224rem;
          text-align: left;
          font-weight: 600;
          font-size: 16rem;
          color: #0A0A0A;
          line-height: 22rem;
          font-family: PingFangSC-Medium;

          .title {
            font-weight: 600;
            font-size: 24rem;
            color: #0A0A0A;
            line-height: 33px;
            margin: 8rem 0 9rem;
          }

          .detail {
            font-weight: 400;
            font-size: 18rem;
            color: #333333;
            line-height: 30rem;
            margin-top: 20rem;
            font-family: PingFangSC-Regular;
          }
        }
      }
    }

    .pagination {
      margin: 50rem 0 57rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &>div {
        width: 24rem;
        height: 24rem;
        line-height: 24rem;
        border-radius: 2rem;
        font-weight: 400;
        font-size: 14rem;
        color: #999999;
        margin: 0 5rem;
        text-align: center;
        cursor: pointer;
      }

      &>div.active {
        background: #F7F7F7;
        color: #414141;
      }

      .prev {
        width: 16rem;
        height: 16rem;
        background-image: url(~@/assets/images/solution/left-c.png);
        background-size: 100% 100%;
      }

      .next {
        width: 16rem;
        height: 16rem;
        background-image: url(~@/assets/images/solution/left-c.png);
        background-size: 100% 100%;
        transform: rotate(180deg);
      }
    }
  }
}
</style>